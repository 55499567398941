import React from 'react';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@mui/material';
import { theme } from './src/styles/theme';
import './src/styles//main.css';
import { Suspense } from 'react';
import Logo from './src/static/svg/siteIcon.svg';
import browserHydrateFunction from './src/utilities/gatsby/browser-hydrate-function';
import { scriptForGTM } from './src/utils/scriptForGTM';

export const replaceHydrateFunction = browserHydrateFunction;

const Prelauder = () => (
  <div
    id='preloader_first_screen'
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999999,
      height: '100vh',
      width: '100vw',
      background: '#F3EFE8',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      overflow: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      MozBackfaceVisibility: 'hidden',
      WebkitTransform: 'translate3d(0, 0, 0)',
      MozTransform: 'translate3d(0, 0, 0)',
    }}
  >
    <Logo alt='Barcelona weed map logo' />
  </div>
);

export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      <Suspense fallback={<Prelauder />}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </Suspense>
    </RecoilRoot>
  );
};

export const onInitialClientRender = () => {
  setTimeout(() => {
    if (process.env.NODE_ENV === 'production') {
      const gtmScript = document.createElement('script');
      gtmScript.defer = true;
      gtmScript.textContent = scriptForGTM;

      document.body.appendChild(gtmScript);
    }
  }, 3000);
};
