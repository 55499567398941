import { createTheme } from '@mui/material';
import './fonts/gilroy.css';

export const colors = {
  mainGray: '#F3EFE8',
  mainYellow: '#FFCF45',
  mainPeach: '#FE9459',
  mainGreen: '#85DCA6',
  secondaryGreen: '#0FBE76',
  mainPurple: '#A1A9FF',
  mainBlue: '#A2C0FF',
  mainWhite: '#FFFFFF',
  mainBlack: '#1C2F2B',
  secondaryBlack: '#434343',
  tertiaryBlack: '#808080',
};

export const theme = createTheme({
  typography: {
    fontFamily: ['Gilroy', 'sans-serif'].join(','),
    color: 'red',
  },
  palette: {
    chip: {
      border: {
        popular: '#FF5C3D',
        new: '#0FBE76',
        rating: '#F0B622',
        members: 'rgba(27, 47, 43, 0.10)',
        close: 'rgba(27, 47, 43, 0.10)',
      },
      background: {
        popular: {
          main: 'rgba(255, 92, 61, 0.75)',
        },
        new: {
          main: 'rgba(15, 190, 118, 0.75)',
        },
        rating: {
          main: 'rgba(240, 182, 34, 0.75)',
        },
        members: {
          main: 'rgba(243, 239, 232, 0.60)',
        },
        close: {
          main: 'rgba(243, 239, 232, 0.60)',
          hover: 'rgba(243, 239, 232, 0.80)',
        },
      },
    },
    white: {
      mainWhite: '#FFFFFF',
    },
    black: {
      mainBlack: '#1C2F2B',
      secondaryBlack: '#434343',
      tertiaryBlack: '#808080',
    },
    grey: {
      mainGray: '#F3EFE8',
    },
    green: {
      mainGreen: '#85DCA6',
      secondaryGreen: '#0FBE76',
    },
    yellow: {
      mainYellow: '#FFCF45',
    },
    orange: {
      mainPeach: '#FE9459',
    },
    blue: {
      mainBlue: '#A2C0FF',
    },
    purple: {
      mainPurple: '#A1A9FF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1024,
      bm: 1440,
      lg: 1920,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.mainBlack,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 24,
          padding: 20,
        },
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            background: colors.mainGray,
            padding: 52,
            borderRadius: 34,
          },
        },
        {
          props: { variant: 'regular' },
          style: {
            background: colors.mainGray,
            padding: 20,
            borderRadius: 24,
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            height: 48,
            borderRadius: 16,
            backgroundColor: '#FFFFFF',
          },
          '.MuiInputBase-input': {
            padding: '12px 15px',
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: 16,
            border: '1px solid rgba(27, 47, 43, 0.10)',
          },

          '& .MuiInputBase-input:hover + fieldset': {
            border: '1px solid rgba(15, 190, 118, 0.30)',
            boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
          },

          '& .MuiInputBase-input:focus + fieldset': {
            border: '1px solid #0FBE76',
            boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
          },

          '& :hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
          },

          '&:focus-within .MuiOutlinedInput-notchedOutline': {
            borderRadius: 16,
            border: '1px solid #0FBE76',
            boxShadow: '0px 0px 0px 4px rgba(15, 190, 118, 0.15)',
          },

          '.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderRadius: 16,
            border: '1px solid rgba(255, 93, 61, 0.30)',
          },

          '& :hover .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderRadius: 16,
            border: '1px solid rgba(255, 93, 61, 0.30)',
            boxShadow: '0px 0px 0px 4px rgba(255, 93, 61, 0.15)',
          },

          '&:focus-within .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderRadius: 16,
            border: '1px solid #FF5D3D',
            boxShadow: '0px 0px 0px 4px rgba(255, 93, 61, 0.15)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#FF5D3D',
          marginLeft: '10px',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: colors.mainGray,
          '&.MuiAccordion-root': {
            borderRadius: 24,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
          padding: 0,
          '& .MuiTypography-root': {
            fontWeight: 1000,
            fontSize: 20,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },

        root: {
          padding: 24,
          transition: 'border-color .3s ease-in-out, border-radius .3s ease',
          borderBottom: '1px solid transparent',
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
          '&.Mui-expanded': {
            margin: 0,
            borderBottom: '1px solid rgba(27, 47, 43, 0.1)',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: colors.mainBlack,
          padding: 24,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '&.MuiCollapse-entered': {
            borderTop: 'none',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'submit' },
          style: {
            marginTop: '11px',
            width: '100%',
            background: colors.secondaryGreen,
            color: 'black',
            height: 48,
            borderRadius: '24px',
            fontFamily: 'Gilroy',
            fontSize: '16px',
            fontWeight: 1000,
            lineHeight: '140%',
            textTransform: 'uppercase',

            '@media (max-width: 1024px)': {
              maxWidth: '471px',

              margin: '0 auto',
              marginTop: '11px',
            },
            '&:hover': {
              background: '#0DAB6A',
            },
            '&:active': {
              background: '#0B8E58',
            },
          },
        },
      ],
    },
  },
});
